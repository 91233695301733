import React from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from 'gatsby'
import {
  Box,
  Flex,
  Heading,
  Img,
  Txt,
  Pager,
  Link as RLink,
  Container,
} from 'rendition'

export const BlogPost = (props) => {
  return (
    <Box width={[1 / 2]}>
      <RLink is={Link} to={props.link}>
        <Flex m={2} alignItems="start" flexDirection="column">
          <Img
            src={props.image}
            width="100%"
            style={{ borderRadius: 10 }}
          ></Img>
          <Txt.span
            mt={3}
            mb={1}
            fontSize={1}
            style={{ alignSelf: 'start' }}
            color="primary.main"
          >
            {props.type}
          </Txt.span>
          <Heading.h4
            fontSize={3}
            mb={0}
            color="text.main"
            style={{ fontWeight: 'normal', lineHeight: 1.3 }}
          >
            {props.title}
          </Heading.h4>
          {props.description && (
            <Txt.p
              my={2}
              color="text.main"
              style={{ opacity: 0.7, lineHeight: 1.71 }}
              fontSize={1}
            >
              {props.description}
            </Txt.p>
          )}
          <Txt.span
            my={1}
            style={{ alignSelf: 'start' }}
            color="text.main"
            fontSize="10px"
          >{`${props.date} / ${props.timeToRead} min`}</Txt.span>
        </Flex>
      </RLink>
    </Box>
  )
}

const BlogRoll = (props) => {
  const { data, currentPage } = props
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Container>
      <Flex style={{ flexFlow: 'row wrap' }}>
        {posts &&
          posts.map(({ node: post }) => (
            <BlogPost
              image={post.frontmatter.feature_image}
              type={post.frontmatter.postType}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              description={post.frontmatter.excerpt}
              timeToRead={post.timeToRead}
              link={post.fields.slug}
            />
          ))}
      </Flex>
      <Pager
        page={currentPage - 1}
        nextPage={() => {
          navigate(`/posts/${currentPage + 1}`)
        }}
        prevPage={() => {
          navigate(`/posts/${currentPage - 1}`)
        }}
        itemsPerPage={16}
        totalItems={data.allMarkdownRemark.totalCount}
      ></Pager>
    </Container>
  )
}

export default BlogRoll

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
