import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/Layout'
import { Container, Flex, Heading, Pager } from 'rendition'
import { BlogPost } from '../components/BlogRoll'
import _ from 'lodash'

const TagRoute = (props) => {
  const posts = props.data.allMarkdownRemark.edges
  const tag = props.pageContext.tag
  const currentPage = props.pageContext.currentPage
  const title = props.data.site.siteMetadata.title
  const totalCount = props.data.allMarkdownRemark.totalCount
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with “${tag}”`

  return (
    <Layout>
      <Container>
        <Helmet title={`${tag} | ${title}`} />
        <Heading.h3>{tagHeader}</Heading.h3>
        <Flex style={{ flexFlow: 'row wrap' }}>
          {posts.map(({ node: post }) => (
            <BlogPost
              image={post.frontmatter.feature_image}
              type={post.frontmatter.postType}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              description={post.excerpt}
              timeToRead={post.timeToRead}
              link={post.fields.slug}
            />
          ))}
        </Flex>
        <Pager
          page={currentPage - 1}
          nextPage={() => {
            navigate(`/tags/${_.kebabCase(tag)}/${currentPage + 1}`)
          }}
          prevPage={() => {
            navigate(`/tags/${currentPage - 1}`)
          }}
          itemsPerPage={16}
          totalItems={totalCount}
        ></Pager>
      </Container>
    </Layout>
  )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount

      edges {
        node {
          excerpt(pruneLength: 400)
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featured
            feature_image
            postType
          }
        }
      }
    }
  }
`
